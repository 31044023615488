import { useRef } from "react";
import { useTranslations, getLangFromUrl } from "@/i18n/utils";
import { motion, useScroll, useTransform } from "framer-motion";

import { useReduceMotion } from "@/hooks/useReduceMotion";

import { CARDS_PRESS_KIT } from "@/lib/web";

import ContainerCards from "./cards/ContainerCard";
import BenefitsHeader from "./BenefitsHeader";
import { useCurrentBreakpoint } from "@/hooks/useCurrentBreakpoint";

interface BenefitsProps {
  url: URL;
}

export default function BenefitsCards({ url }: BenefitsProps) {
  const lang = getLangFromUrl(url);
  const t = useTranslations(lang);

  const BASE_TRANSLATE = "home.benefitsSection";

  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });
  const { reducedMotion } = useReduceMotion();

  const breakpoint = useCurrentBreakpoint();

  const positionCardMiddle = () => {
    if (breakpoint === "sm") {
      return 180;
    } else if (breakpoint === "base" || breakpoint === "md") {
      return 131;
    } else {
      return 137;
    }
  };
  const positionCardLast = () => {
    if (breakpoint === "sm") {
      return 212;
    } else if (breakpoint === "base" || breakpoint === "md") {
      return 185;
    } else {
      return 185;
    }
  };

  const cardTransforms = [
    useTransform(scrollYProgress, [0, 0.33], [0, 0]),
    useTransform(scrollYProgress, [0.33, 0.33], [0, positionCardMiddle()]),
    useTransform(scrollYProgress, [0.66, 0.66], [0, positionCardLast()]),
  ];

  return (
    <div
      ref={containerRef}
      className="relative col-span-full mb-52 grid grid-cols-subgrid md:mb-24 xl:mb-20"
    >
      {CARDS_PRESS_KIT.map((item, index) => (
        <motion.div
          key={index}
          className="sticky top-20 col-span-full grid h-[500px] grid-cols-subgrid will-change-transform md:top-24 xl:top-32"
          style={{
            y: cardTransforms[index],
            zIndex: index + 1,
          }}
          transition={{
            type: "spring",
            stiffness: reducedMotion ? 100 : 40,
            damping: reducedMotion ? 30 : 25,
            ease: [0.25, 0.46, 0.45, 0.94],
          }}
        >
          {index === 0 && <BenefitsHeader url={url} />}

          <ContainerCards
            title={String(t(`${BASE_TRANSLATE}.${item.title}`))}
            subtitle={String(t(`${BASE_TRANSLATE}.${item.subtitle}`))}
            description={String(t(`${BASE_TRANSLATE}.${item.description}`))}
            url={url}
            list={item.list}
            image={item.image}
            color={item.color}
          />
        </motion.div>
      ))}
    </div>
  );
}
