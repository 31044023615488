import { CheckCircle } from "@phosphor-icons/react/dist/ssr";
import type { Icon } from "@phosphor-icons/react";

const BLOB_URL = import.meta.env.PUBLIC_STORAGE_URL;

export type LIST_ITEM = {
  icon: Icon;
  text: string;
};

type CARDS_PRESS_KIT = {
  title: string;
  subtitle: string;
  description: string;
  list: LIST_ITEM[];
  image: string;
  color: "black" | "lime" | "success" | "indigo";
};

const LISTS_PRESS_KIT = {
  CREATE: [
    {
      icon: CheckCircle,
      text: "firstCard.listOne",
    },
    {
      icon: CheckCircle,
      text: "firstCard.listTwo",
    },
    {
      icon: CheckCircle,
      text: "firstCard.listThree",
    },
  ],
  BOOST: [
    {
      icon: CheckCircle,
      text: "secondCard.listOne",
    },
    {
      icon: CheckCircle,
      text: "secondCard.listTwo",
    },
    {
      icon: CheckCircle,
      text: "secondCard.listThree",
    },
  ],
  OPTIMIZE: [
    {
      icon: CheckCircle,
      text: "thirdCard.listOne",
    },
    {
      icon: CheckCircle,
      text: "thirdCard.listTwo",
    },
    {
      icon: CheckCircle,
      text: "thirdCard.listThree",
    },
  ],
};

const IMAGES_PRESS_KIT = {
  CREATE: `${BLOB_URL}/web-image/press-kit/create-your-press-kit.svg`,
  BOOST: `${BLOB_URL}/web-image/press-kit/boost_your_visibility.svg`,
  OPTIMIZE: `${BLOB_URL}/web-image/press-kit/optimize_your_bio.svg`,
};

export const CARDS_PRESS_KIT: CARDS_PRESS_KIT[] = [
  {
    title: "firstCard.title",
    subtitle: "firstCard.subtitle",
    description: "firstCard.description",
    list: LISTS_PRESS_KIT.CREATE,
    image: IMAGES_PRESS_KIT.CREATE,
    color: "lime",
  },
  {
    title: "secondCard.title",
    subtitle: "secondCard.subtitle",
    description: "secondCard.description",
    list: LISTS_PRESS_KIT.BOOST,
    image: IMAGES_PRESS_KIT.BOOST,
    color: "success",
  },
  {
    title: "thirdCard.title",
    subtitle: "thirdCard.subtitle",
    description: "thirdCard.description",
    list: LISTS_PRESS_KIT.OPTIMIZE,
    image: IMAGES_PRESS_KIT.OPTIMIZE,
    color: "indigo",
  },
];
