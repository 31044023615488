import { useTranslations, getLangFromUrl } from "@/i18n/utils";

import type { LIST_ITEM } from "@/lib/web/press-kit";

import Card from "@/components/shared/card/Card";
import Divider from "@/components/shared/divider/Divider";
import Heading from "@/components/shared/text/heading";
import Paragraph from "@/components/shared/text/paragraph";

interface ContainerCardsPropsProps {
  title: string;
  image: string;
  subtitle: string;
  description: string;
  list: LIST_ITEM[];
  color: "black" | "lime" | "success" | "indigo";
  url: URL;
}

export default function ContainerCards({
  title,
  image,
  subtitle,
  description,
  list,
  color,
  url,
}: ContainerCardsPropsProps) {
  const lang = getLangFromUrl(url);
  const t = useTranslations(lang);

  return (
    <div className="col-span-full grid min-h-[500px] grid-cols-subgrid md:col-start-2 md:col-end-12">
      <Card
        grid
        className="col-span-full"
        classNameQuaternary="!items-start !justify-start !pt-10 !px-6 md:!pr-20 md:!py-[90px] md:!pl-[66px]"
        color={color}
      >
        <section className="col-span-full grid grid-cols-subgrid">
          <div className="relative col-span-full hidden grid-cols-subgrid md:col-end-5 md:mr-8 md:grid">
            <img
              className="absolute mt-9 object-cover"
              alt="plug in image"
              src={image}
              width={1800}
              height={600}
              decoding="async"
              loading="eager"
            />
          </div>
          <div className="col-span-full grid grid-cols-subgrid space-y-2 md:space-y-8">
            <div className="col-span-full grid grid-cols-subgrid md:col-start-5">
              <Heading
                weight="semiBold"
                className="col-span-full text-headingXs text-white sm:text-headingLg md:col-span-3 md:text-headingXl lg:col-span-3 lg:text-headingXxl"
              >
                {title}
              </Heading>
              <Paragraph className="hidden self-end text-white-70 md:col-span-full md:col-start-4 md:block md:text-textLg lg:col-start-4">
                {subtitle}
              </Paragraph>
            </div>
            <div className="col-span-full hidden md:col-start-5 md:block">
              <Divider />
            </div>
            <div className="col-span-full grid grid-cols-subgrid md:col-start-5">
              <Paragraph className="col-span-full text-white-70 sm:text-textMd md:col-span-3">
                {description}
              </Paragraph>
              <ul className="col-span-full hidden space-y-2 text-white-70 md:col-start-4 md:block md:text-textLg">
                {list.map((item) => {
                  const IconComponent = item.icon;
                  return (
                    <li key={item.text} className="flex items-center gap-x-3">
                      <IconComponent weight="fill" size={24} />{" "}
                      {String(t(`home.benefitsSection.${item.text}`))}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="relative col-span-full mt-8 grid h-[225px] grid-cols-subgrid place-items-center md:col-end-4 md:hidden">
            <img
              className="absolute col-span-full w-3/4 self-start object-cover sm:w-1/2"
              alt="plug in image"
              src={image}
              width={1800}
              height={600}
              decoding="async"
              loading="eager"
            />
          </div>
        </section>
      </Card>
    </div>
  );
}
