import clsx from "clsx";

type Props = {
  classBorder?: string;
  icon?: React.ReactNode;
  label?: string;
};

export default function Divider({ classBorder, icon, label }: Props) {
  return (
    <div className="relative flex items-center">
      <div
        className={clsx("flex-grow border-t border-white-30", classBorder)}
      ></div>

      <div className="px-2 pb-[2px]">
        {label && <span className="text-sm text-white-80">{label}</span>}
        {icon && <span className="text-white-80">{icon}</span>}
      </div>

      {label && (
        <div
          className={clsx("flex-grow border-t border-white-30", classBorder)}
        ></div>
      )}
    </div>
  );
}
